import dayjs from 'dayjs';
import { FieldName } from 'react-hook-form';
import { SelectOption } from '../components/basics/ControlledComboBox';

export const convertISOToDate = (dateStr: string) => {
  if (!dateStr || typeof dateStr !== 'string') {
    throw new Error('Input must be a string in the format YYYY-MM-DD');
  }

  const [year, month, day] = dateStr.split('-');

  if (!day || !month || !year || day.length !== 2 || month.length !== 2 || year.length !== 4) {
    throw new Error('Date string must be in the format YYYY-MM-DD');
  }

  return `${day}/${month}/${year}`;
};
export const convertDateToISO = (dateStr: string) => {
  if (!dateStr || typeof dateStr !== 'string') {
    throw new Error('Input must be a string in the format DD/MM/YYYY');
  }
  const [day, month, year] = dateStr.split('/');

  if (!day || !month || !year || day.length !== 2 || month.length !== 2 || year.length !== 4) {
    throw new Error('Date string must be in the format DD/MM/YYYY');
  }

  return `${year}-${month}-${day}`;
};

export const formatDateToApi = (date: Date | string) => {
  if (date) {
    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return dayjs(new Date()).format('YYYY-MM-DD');
};

export const formatDateTimeToApi = (date: string, start_hour: boolean) => {
  const [day, month, year] = date.split('/');
  const hour = start_hour ? '00:00:00' : '23:59:59';
  const data = `${year}-${month}-${day} ${hour}`;

  return data;
};

export function formatDate(dataString: string) {
  const options = { timeZone: 'America/Sao_Paulo', year: 'numeric', month: '2-digit', day: '2-digit' };
  const date = new Date(dataString);
  return date.toLocaleDateString('pt-BR', options);
}

export function formatTime(dateString: string): string {
  const date: Date = new Date(dateString);

  const formattedTime: string = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  return formattedTime;
}

export function formatDateTime(dateString: string): string {
  const formattedDate: string = formatDate(dateString);
  const formattedTime: string = formatTime(dateString);

  const formattedDateTime: string = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
}

export function formatCpfCnpj(value: string) {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length === 11) {
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  if (cleanedValue.length === 14) {
    return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return value;
}

export function setInputErrorsFromApi<T>(setError, errorsObject) {
  let formError: string;
  Object.keys(errorsObject).forEach((key) => {
    const keyOf = key as FieldName<T>;
    if (keyOf != 'detail') {
      setError(keyOf, { message: errorsObject[key][0], type: 'manual' });
    } else {
      formError = errorsObject[key];
    }
  });
  if (formError) return formError;
}

export function findSelectedOption(options: SelectOption[], query: any): SelectOption | undefined {
  const selectedOption = options?.find((option) => option.id == query);

  console.log('selectedOption', selectedOption);
  return selectedOption as SelectOption;
}

export function findSelectedOptions(options: SelectOption[], queries?: any[]): SelectOption[] {
  if (!queries || queries?.length === 0) {
    return [];
  }

  const queryIds = queries?.map((query) => query?.id);
  const foundOptions = options?.filter((item) => queryIds?.includes(item.id));

  console.log('foundOptions', foundOptions);

  return foundOptions;
}

export const validateCpf = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return true;
  }

  var digitoVerificador = cpf.substring(9);
  var soma = 0;

  for (var i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  var resto = soma % 11;
  var primeiroDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(0)) !== primeiroDigito) {
    return false;
  }

  soma = 0;

  for (var i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  resto = soma % 11;
  var segundoDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(1)) !== segundoDigito) {
    return false;
  }

  return true;
};

export const validateCnpj = (cnpj) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (caso contrário, passaria na validação do tamanho)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Calcula os dígitos verificadores
  const calcDigit = (str, factor) => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += parseInt(str.charAt(i), 10) * factor--;
      if (factor < 2) {
        factor = 9;
      }
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstDigit = calcDigit(cnpj.substring(0, 12), 5);
  const secondDigit = calcDigit(cnpj.substring(0, 13), 6);

  // Verifica se os dígitos verificadores calculados são iguais aos dígitos do CNPJ
  if (parseInt(cnpj.charAt(12), 10) !== firstDigit || parseInt(cnpj.charAt(13), 10) !== secondDigit) {
    return false;
  }

  return true;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  // Remova o caractere '#' do início, se presente.
  hex = hex.replace('#', '');

  // Converta os valores de cor em números inteiros.
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Garanta que o valor alfa esteja no intervalo válido.
  alpha = Math.min(1, Math.max(0, alpha));

  // Crie a representação RGBA da cor.
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatCurrency = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formatCurrencyDollar = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  const formattedValue = numericValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formattedValue.replace('$', '$ ');
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const labelDisplayedRows = ({ from, to, count }) => {
  return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
};
