import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import MainLayout from '../layout/MainLayout';
import { AvailabilityPageWrapper } from '../pages/private/Availability';
import { AvailabilityValuePageWrapper } from '../pages/private/AvailabilityValue';
import { CompanyParametersPageWrapper } from '../pages/private/CompanyParameters';
import { IntegrationLogPageWrapper } from '../pages/private/IntegrationLog';
import { LinkCustomerPageWrapper } from '../pages/private/LinkCustomer';
import { LinkProductPageWrapper } from '../pages/private/LinkProduct';
import { LinkRepresentativePageWrapper } from '../pages/private/LinkRepresentative';
import { LinkSaleDivisionPageWrapper } from '../pages/private/LinkSaleDivision';
import { PaymentOrderPageWrapper } from '../pages/private/PaymentOrder';
import { PermissionGroupsPageWrapper } from '../pages/private/PermissionGroups';
import { PositionAvailabilityValueWrapper } from '../pages/private/PositionAvailabilityValue';
import { ProfilePageWrapper } from '../pages/private/Profile';
import { UsersPageWrapper } from '../pages/private/Users';
import { ROUTES } from './routes';

const DashboardDefault = Loadable(lazy(() => import('../pages/private/Dashboard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: ROUTES.authenticated.dashboard.home,
      element: <DashboardDefault />
    },
    {
      path: ROUTES.authenticated.user.profile,
      element: <ProfilePageWrapper />
    },
    {
      path: ROUTES.authenticated.access.users,
      element: <UsersPageWrapper />
    },
    {
      path: ROUTES.authenticated.access.permissionGroups,
      element: <PermissionGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.parameters,
      element: <CompanyParametersPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_representative,
      element: <LinkRepresentativePageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_sale_division,
      element: <LinkSaleDivisionPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_customer,
      element: <LinkCustomerPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.payment_order,
      element: <PaymentOrderPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.availability,
      element: <AvailabilityPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.availability_value,
      element: <AvailabilityValuePageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.position_availability_value,
      element: <PositionAvailabilityValueWrapper />
    },
    {
      path: ROUTES.authenticated.integration.integration_log,
      element: <IntegrationLogPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_product,
      element: <LinkProductPageWrapper />
    }
  ]
};

export default MainRoutes;
