export const ROUTES = {
  public: {
    login: '/login',
    signUp: '/cadastro',
    accountConfirmation: '/confirmacao-de-conta'
  },
  authenticated: {
    dashboard: {
      home: '/inicio'
    },
    user: {
      profile: '/perfil'
    },
    access: {
      users: '/usuarios',
      permissionGroups: '/grupos-permissoes'
    },
    auxiliary: {
      parameters: '/parameters'
    },
    finance: {
      payment_order: '/ordem-de-pagamento',
      availability: '/disponibilidade',
      availability_value: '/saldo-disponibilidade',
      position_availability_value: '/posicao-saldo'
    },
    integration: {
      link_representative: '/vincular-representante',
      link_sale_division: '/vincular-divisao-venda',
      link_customer: '/vincular-cliente',
      link_product: '/vincular-produto',
      integration_log: '/logs-integracao'
    }
  }
};
